@use 'variables/colors';

.PageFooter {
  text-align: center;
  margin-bottom: 12px;
  margin-top: 24px;
}

.PageFooter__links {
  display: block;
  margin-top: 1em;
  padding: 0;
  font-size: 0.85em;
}

.PageFooter__links > li {
  display: inline-block;
  margin-left: 1em;
}

.PageFooter__links > li:before {
  content: '|';
  color: colors.$brand-primary;
  margin-right: 1em;
}

.PageFooter__links > li:first-child:before {
  display: none;
}

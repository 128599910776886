@use 'variables/colors';

.JobListingsPage__list {
  list-style-type: none;
  padding-left: 0;
}

.JobListingsPage__list-item {
  margin-top: 12px;
  margin-bottom: 12px;
}

.JobListingsPage__mainTitle {
  margin-bottom: 24px;
}

.JobListingsPage__empty--all,
.JobListingsPage__empty--filtered {
  border-radius: 4px;
  padding: 16px;
  background-color: colors.$white;
  border: 1px solid colors.$gray-20;
}

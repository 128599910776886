@use 'variables/borders';
@use 'variables/colors';

$input-width-small: 80px;
$input-width-medium: 200px;
$input-width-large: 320px;
$input-width-full: 100%;

// Bootstrap overrides
$input-color: colors.$gray-60;
$input-height-base: 30px;
$input-height-small: 26px;
$input-border-color: colors.$gray-30;
$input-border-focus: colors.$gray-30;
$input-border-radius: borders.$border-radius-base;
$input-color-placeholder: colors.$gray-30;
$input-box-shadow: 0 1px 3px 0 rgba(colors.$black-shadow, 0.12);

@use 'variables/colors';

.ContentSection {
  background-color: colors.$white;
  border: 1px solid colors.$gray-20;
  border-radius: 4px;
}

.JobPageContent {
  margin-top: 1.5em;
  margin-bottom: 1em;
}

.JobPageContent > .ContentSection {
  padding: 1em;
  margin-bottom: 1.5em;
}

.JobPageContent > .ContentSection > h2 {
  margin-bottom: 24px;
}

.JobPageContent__ContentSection > .JobShareLinks {
  float: right;
}

// =============================================================================
// GLOBAL COLOR VARIABLES
//
// These values map to the color specifications of our Design System and should
// not be edited or appended without consulting the design team.
// =============================================================================

// Black Shadow is only to be used for shadows and transparent
// borders/backgrounds, never for text.
$black-shadow: #000000;
$gray-60: #333333;
$gray-50: #666666;
$gray-40: #939ab4;
$gray-30: #c0c4d3;
$gray-20: #dee0e8;
$gray-10: #eeeef3;
$gray-5: #f7f8fa;
$white: #ffffff;

$green: #5cb85c;
$blue: #5bc0de;
$orange: #f0ad4e;
$red: #f91111;
$light-blue: #d9ecf3;

// Specifics
$brand-success: $green;
$brand-info: $blue;
$brand-warning: $orange;
$brand-danger: $red;

$body-bg: $gray-10;
$color-border: $gray-20;
$text-color: $gray-50;

// Other brands
$linkedin-blue: #007bb6;
$crunchbase-green: #2292a7;

// Brand colors
$brand-blue: #4640ed;
$brand-dark-purple: #505882;
$brand-dark-blue: #2b2b4f;
$brand-light-purple: #eff1f7;
$brand-green: #77d59d;
$brand-red: #d5403c;
$brand-orange: #ffaf62;
$brand-yellow: #ffd362;
$brand-purple: #af62ff;
$vivid-blue: #1c15e5;
$dusk: #484f75;
$brick: #b92c27;
$brand-primary: $brand-blue;
$brand-primary-light: lighten($brand-primary, 10%);

$link-color: $brand-primary;
$link-hover-color: $brand-primary-light;

$header-link-color: $white;
$header-link-hover-color: $gray-50;

$action: $brand-primary;
$bullseye: $brand-primary;

$deleted-table-rowbackground-color: rgba($brand-danger, 0.1);

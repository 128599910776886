@use 'variables/colors';
@use 'variables/spacing';
@use 'variables/inputs';

.ApplyModal__dialog .modal-content {
  background-color: colors.$white;
  border-radius: 8px;
  box-shadow: 0 10px 18px 0 rgba(colors.$black-shadow, 0.15);
  border: none;
}

.ApplyModal__Header {
  position: relative;
  .btn-close {
    border: 1px solid colors.$gray-30;
    border-radius: spacing.$space-4;
    box-shadow: inputs.$input-box-shadow;
    height: inputs.$input-height-base;
    width: inputs.$input-height-base;
    background: transparent
      url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E")
      50%/1em auto no-repeat;
    position: absolute;
    top: spacing.$space-16;
    right: 0;
    &:focus {
      background-color: colors.$gray-20;
    }
    &:hover {
      background-color: colors.$gray-10;
    }
  }
  border: none;
  margin: 8px 16px 8px 16px;
  padding: 16px 8px;
  border-bottom: 1px solid colors.$gray-60;
}
.ApplyModal__Body.modal-body {
  margin: 8px 16px 8px 16px;
  padding: 16px 8px;
}

.ApplyModal__Footer.modal-footer {
  border: none;
  margin: 8px 16px 8px 16px;
  padding: 16px 8px;
}

.ApplyModal__Title.modal-title {
  font-size: 24px;
  text-align: center;
}

.ApplyModal .JobLocation + .JobPlacementType {
  border-left: 2px solid colors.$gray-40;
  margin-left: 0.75em;
  padding-left: 0.75em;
}

.ApplyModal__CaslAddress {
  text-align: right;
  margin-top: 2em;
}

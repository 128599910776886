@use 'variables/colors';

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.3em;
  background: colors.$gray-10;
  color: colors.$gray-60;
}

h1 {
  font-size: 2.1em;
  margin: 0;
  font-weight: 600;
}

h2 {
  font-size: 1.4em;
  margin: 0;
  font-weight: 600;
}

h3 {
  font-size: 16px;
  margin: 0;
  font-weight: 600;
}

h4 {
  font-size: 1.1em;
  margin: 0;
  font-weight: 600;
}

a {
  color: colors.$link-color;
}

a:hover {
  color: colors.$link-hover-color;
  text-decoration: none;
}

/* The below styles are shared across all buttons.  
  We use react-bootstrap and pure css so !important is used as an exception 
*/
.btn {
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  min-height: 40px !important;
  min-width: 40px;
  text-shadow: none !important;
}

@use 'variables/colors';
@use 'variables/z-index';

.AppHeader.navbar {
  z-index: z-index.$z-index-header;
  margin-bottom: 0;
  box-shadow: 0 2px 4px 0 rgba(colors.$black-shadow, 0.26);
  background-image: none;
  background-color: colors.$white;
  /* Important is needed here to supercede the bootstrap navbar styling which is larger*/
  height: 48px !important;
  box-sizing: border-box;
  position: sticky;
  top: 0;
}

a.AppHeader__signOutLink {
  line-height: 48px;
  max-height: 48px;
}
.AppHeader .navbar-brand {
  padding-top: 8px;
}

/* The header links need to be manually overriden this way
 * because bootstrap is so specific when styling them that just
 * using our PrimaryLink class or something similar won't work */
.AppHeader.navbar-default .navbar-nav > li > a,
.AppHeader.navbar-default .navbar-nav > li > a:visited {
  color: colors.$link-color;
}

.AppHeader.navbar-default .navbar-nav > li > a:hover {
  color: colors.$link-hover-color;
}

.AppHeader.navbar-default .navbar-nav > li > a:focus {
  outline-color: colors.$link-color;
  outline-width: 2px;
}

.JobPageSidebar__back-button.btn {
  text-align: left;
  padding-left: 0;
  line-height: 24px;
  margin-bottom: 8px;
}

.JobPageSidebar__section {
  margin-top: 1em;
  margin-bottom: 1em;
}

.JobPageSidebar__title {
  margin-bottom: 0.25em;
}

.JobPageSidebar__function {
  margin-top: 0.25em;
}

.JobPageSidebar .JobLocation,
.JobPageSidebar .JobPlacementType {
  display: block;
}

// =============================================================================
// GLOBAL TYPOGRAPHY VARIABLES
//
// These values map to the typography specifications of our Design System and
// should not be edited or appended without consulting the design team.
// =============================================================================

$font-size-base: 14px;
$font-size-small: 12px;
$font-size-large: 16px;

$font-weight-normal: 500;
$font-weight-bold: 600;

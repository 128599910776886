@use 'variables/colors';

.FilterLabel {
  display: flex;
  align-items: center;
}

.FilterLabel--count:before {
  content: '(';
}

.FilterLabel--count:after {
  content: ')';
}

.FilterLabel--count {
  margin-left: 0.5em;
  color: colors.$brand-primary;
}

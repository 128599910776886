@use 'variables/colors';

@keyframes rotateAnimation {
  /* We're repurposing the "ThriveDown" logo which is "upside-down",
     so start it 180 degrees so it appears "right-side-up" */
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes moveToTop {
  from {
  }
  to {
    transform: translateY(-100%);
  }
}

.Splash {
  /* Position the splash as an "overlay" */
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  background-color: #eee;

  /* The nav/header has a z-index of 1030, so this has to be higher than that,
     otherwise the header will appear while the splash screen animates away */
  z-index: 1031;
}

.Splash.splash-exit-active {
  animation: moveToTop 1200ms ease both;
}

.Splash.splash-exit-active > .Splash__logo {
  /* Stop rotating when the splash page is animating away
     but make sure the logo is upright so it points in the direction
     of the animation! */
  transform: rotate(180deg);
  animation: none;
}

.Splash__logo {
  height: 120px;

  /* Rotate the logo while we load */
  animation: rotateAnimation 1s infinite ease-in-out;
}

.Splash__title {
  color: colors.$brand-primary;
  font-size: 2em;
}

.Splash__message {
  color: colors.$gray-50;
}

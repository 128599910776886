@use 'variables/colors';

.PageError {
  border-radius: 4px;
  padding: 16px;

  background-color: colors.$white;
  border: 1px solid colors.$gray-20;
}

.PageError > h3 {
  color: colors.$brand-red;
  margin-bottom: 12px;
}

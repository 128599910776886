@use 'variables/colors';

.JobListingSummary {
  position: relative;
}

.JobListingSummary__body {
  margin-right: 3.5em;
  padding: 16px;
}

.JobListingSummary__job-title,
.JobListingSummary__job-function {
  margin-top: 0;
  margin-bottom: 0.25em;
}

.JobListingSummary__time {
  text-align: right;
  margin-top: 0.5em;
}

.JobListingSummary__time.col-xs-12 {
  text-align: left;
}

.JobListingSummary .JobDescriptionSummary {
  margin-top: 1em;
}

.JobListingSummary,
.JobListingSummary .JobPlacementType {
  display: block;
}

.JobLocation {
  display: inline-flex;
  align-items: center;

  &__icon {
    margin-left: 4px;
  }
}

.JobListingSummary__select {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 3em;
  text-align: center;
  border-left: 1px solid colors.$gray-20;
}

.JobListingSummary__select-link {
  display: inline-flex;
  height: 100%;
  font-size: 1.5em;
  color: colors.$gray-60;
}

.JobListingSummary__select-link > i {
  position: relative;
  top: 45%;
  transform: translateY(-50%);
}

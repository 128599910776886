@use 'variables/colors';
@use 'variables/spacing';

.FormDropzone__dropBox {
  border: 1px dotted colors.$gray-30;
  padding: spacing.$space-12;
  margin-bottom: spacing.$space-16;
  text-align: center;
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

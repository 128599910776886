@use 'variables/colors';

.PrimaryLink,
.PrimaryLink:visited {
  color: colors.$brand-primary;
  font-size: 12px;
  text-decoration: underline;
}

.PrimaryLink:hover {
  color: colors.$link-hover-color;
}

.PrimaryLink:focus {
  outline-color: colors.$brand-primary;
  outline-width: 2px;
}

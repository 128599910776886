// =============================================================================
// GLOBAL SPACING VARIABLES
//
// These values map to the spacing increments of our Design System and should
// not be edited or appended without consulting the design team.
// =============================================================================

$space-2: 2px;
$space-4: 4px;
$space-8: 8px;
$space-12: 12px;
$space-16: 16px;
$space-24: 24px;
$space-32: 32px;
$space-64: 64px;

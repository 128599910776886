@use 'variables/colors';

.ButtonLink.btn-link {
  color: colors.$link-color;
}

.ButtonLink.btn-link:hover {
  color: colors.$link-hover-color;
  text-decoration: none;
}

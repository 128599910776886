@use 'variables/colors';
@use 'variables/spacing';
@use 'variables/typography';

/**
 * CSS UTILITIES
 *
 * Global single-responsibility utility classes which allow for common display tweaks
 * without the need to add redundant page-level CSS. These classes are intended to
 * trump any other styles inherited by the target element, and are one of the few
 * places where `!important` declarations are acceptable.
 *
 * BEM syntax is not used for utilities because they intentionally have minimal
 * nesting and states. Instead, a "u" prefix is used to establish a global namespace
 * to prevent collisions. Also, since utility classes typically have a single
 * declaration, single-line format is used here to highlight differences between
 * each rule. Prettier formatting is disabled for this file via a `.prettierignore`
 * in the root of the project.
 */

// WHITESPACE
.u-noWrap {
  white-space: nowrap !important;
}

// WORDBREAK
.u-wordBreak {
  word-break: break-word !important;
}

// DISPLAY
.u-inline {
  display: inline;
}
.u-block {
  display: block;
}
.u-inlineBlock {
  display: inline-block;
}
.u-hidden {
  display: none;
}
.u-flex {
  display: flex;
}
.u-inlineFlex {
  display: inline-flex;
}

// WIDTH
.u-width-100 {
  width: 100%;
}

// NOTE: an explicit width must be defined on the element in order for this style to take effect.
// In many cases it is fine to do this as an inline style:
// <div class="u-truncate" style="width: 100px">This is my text to truncate</div>
.u-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// HELP TEXT
.u-helpText {
  border-bottom-style: dotted;
  border-bottom-width: 1px;
  cursor: help;
  white-space: nowrap;
}

// TEXT ALIGNMENT
.u-textAlign-c {
  text-align: center !important;
}
.u-textAlign-l {
  text-align: left !important;
}
.u-textAlign-r {
  text-align: right !important;
}

// VERTICAL ALIGNMENT
.u-verticalAlign-t {
  vertical-align: top !important;
}
.u-verticalAlign-m {
  vertical-align: middle !important;
}
.u-verticalAlign-b {
  vertical-align: bottom !important;
}

// FLEX ALIGNMENT
.u-flexAlign-c {
  align-items: center;
}
.u-flexAlign-l {
  align-items: flex-start;
}
.u-flexAlign-r {
  align-items: flex-end;
}
.u-flexJustify-c {
  justify-content: center;
}
.u-flexJustify-spaceBetween {
  justify-content: space-between;
}
.u-flexJustify-r {
  justify-content: flex-end;
}

// FLEX ITEMS

// Flex Item Grow
// Width increases to fill space in flex container
// Width decreases to accommodate sibling flex items without grow
.u-flexItem-grow {
  flex-grow: 1 !important;
  // Override flex item's default min-width of auto so that ellipsis truncation works
  min-width: 0;
}

// Strong Flex Item
// Width is shrinkwrapped, and will increase as much as necessary to fit its content
// Forces weak flex item to descrease width
.u-flexItem--strong {
  flex-shrink: 0 !important;
}

// BORDER
.u-border {
  border: 1px solid colors.$gray-30 !important;
}
.u-border-n {
  border: none !important;
}
.u-borderTop {
  border-top: 1px solid colors.$gray-30 !important;
}
.u-borderRight {
  border-right: 1px solid colors.$gray-30 !important;
}
.u-borderBottom {
  border-bottom: 1px solid colors.$gray-30 !important;
}
.u-borderLeft {
  border-left: 1px solid colors.$gray-30 !important;
}
.u-borderHorizontal {
  border-left: 1px solid colors.$gray-30 !important;
  border-right: 1px solid colors.$gray-30 !important;
}
.u-borderVertical {
  border-bottom: 1px solid colors.$gray-30 !important;
  border-top: 1px solid colors.$gray-30 !important;
}

//MARGIN
.u-margin-n {
  margin: 0 !important;
}
.u-marginTop-n {
  margin-top: 0 !important;
}
.u-marginRight-n {
  margin-right: 0 !important;
}
.u-marginBottom-n {
  margin-bottom: 0 !important;
}
.u-marginLeft-n {
  margin-left: 0 !important;
}
.u-marginHorizontal-n {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.u-marginVertical-n {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.u-margin-2 {
  margin: spacing.$space-2 !important;
}
.u-marginTop-2 {
  margin-top: spacing.$space-2 !important;
}
.u-marginRight-2 {
  margin-right: spacing.$space-2 !important;
}
.u-marginBottom-2 {
  margin-bottom: spacing.$space-2 !important;
}
.u-marginLeft-2 {
  margin-left: spacing.$space-2 !important;
}
.u-marginHorizontal-2 {
  margin-left: spacing.$space-2 !important;
  margin-right: spacing.$space-2 !important;
}
.u-marginVertical-2 {
  margin-bottom: spacing.$space-2 !important;
  margin-top: spacing.$space-2 !important;
}
.u-marginSquish-2 {
  margin: spacing.$space-2 spacing.$space-4 !important;
}

.u-margin-4 {
  margin: spacing.$space-4 !important;
}
.u-marginTop-4 {
  margin-top: spacing.$space-4 !important;
}
.u-marginRight-4 {
  margin-right: spacing.$space-4 !important;
}
.u-marginBottom-4 {
  margin-bottom: spacing.$space-4 !important;
}
.u-marginLeft-4 {
  margin-left: spacing.$space-4 !important;
}
.u-marginHorizontal-4 {
  margin-left: spacing.$space-4 !important;
  margin-right: spacing.$space-4 !important;
}
.u-marginVertical-4 {
  margin-bottom: spacing.$space-4 !important;
  margin-top: spacing.$space-4 !important;
}
.u-marginSquish-4 {
  margin: spacing.$space-4 spacing.$space-8 !important;
}

.u-margin-8 {
  margin: spacing.$space-8 !important;
}
.u-marginTop-8 {
  margin-top: spacing.$space-8 !important;
}
.u-marginRight-8 {
  margin-right: spacing.$space-8 !important;
}
.u-marginBottom-8 {
  margin-bottom: spacing.$space-8 !important;
}
.u-marginLeft-8 {
  margin-left: spacing.$space-8 !important;
}
.u-marginHorizontal-8 {
  margin-left: spacing.$space-8 !important;
  margin-right: spacing.$space-8 !important;
}
.u-marginVertical-8 {
  margin-bottom: spacing.$space-8 !important;
  margin-top: spacing.$space-8 !important;
}
.u-marginSquish-8 {
  margin: spacing.$space-8 spacing.$space-12 !important;
}

.u-margin-12 {
  margin: spacing.$space-12 !important;
}
.u-marginTop-12 {
  margin-top: spacing.$space-12 !important;
}
.u-marginRight-12 {
  margin-right: spacing.$space-12 !important;
}
.u-marginBottom-12 {
  margin-bottom: spacing.$space-12 !important;
}
.u-marginLeft-12 {
  margin-left: spacing.$space-12 !important;
}
.u-marginHorizontal-12 {
  margin-left: spacing.$space-12 !important;
  margin-right: spacing.$space-12 !important;
}
.u-marginVertical-12 {
  margin-bottom: spacing.$space-12 !important;
  margin-top: spacing.$space-12 !important;
}
.u-marginSquish-12 {
  margin: spacing.$space-12 spacing.$space-16 !important;
}

.u-margin-16 {
  margin: spacing.$space-16 !important;
}
.u-marginTop-16 {
  margin-top: spacing.$space-16 !important;
}
.u-marginRight-16 {
  margin-right: spacing.$space-16 !important;
}
.u-marginBottom-16 {
  margin-bottom: spacing.$space-16 !important;
}
.u-marginLeft-16 {
  margin-left: spacing.$space-16 !important;
}
.u-marginHorizontal-16 {
  margin-left: spacing.$space-16 !important;
  margin-right: spacing.$space-16 !important;
}
.u-marginVertical-16 {
  margin-bottom: spacing.$space-16 !important;
  margin-top: spacing.$space-16 !important;
}
.u-marginSquish-16 {
  margin: spacing.$space-16 spacing.$space-24 !important;
}

.u-margin-24 {
  margin: spacing.$space-24 !important;
}
.u-marginTop-24 {
  margin-top: spacing.$space-24 !important;
}
.u-marginRight-24 {
  margin-right: spacing.$space-24 !important;
}
.u-marginBottom-24 {
  margin-bottom: spacing.$space-24 !important;
}
.u-marginLeft-24 {
  margin-left: spacing.$space-24 !important;
}
.u-marginHorizontal-24 {
  margin-left: spacing.$space-24 !important;
  margin-right: spacing.$space-24 !important;
}
.u-marginVertical-24 {
  margin-bottom: spacing.$space-24 !important;
  margin-top: spacing.$space-24 !important;
}
.u-marginSquish-24 {
  margin: spacing.$space-24 spacing.$space-32 !important;
}

.u-margin-32 {
  margin: spacing.$space-32 !important;
}
.u-marginTop-32 {
  margin-top: spacing.$space-32 !important;
}
.u-marginRight-32 {
  margin-right: spacing.$space-32 !important;
}
.u-marginBottom-32 {
  margin-bottom: spacing.$space-32 !important;
}
.u-marginLeft-32 {
  margin-left: spacing.$space-32 !important;
}
.u-marginHorizontal-32 {
  margin-left: spacing.$space-32 !important;
  margin-right: spacing.$space-32 !important;
}
.u-marginVertical-32 {
  margin-bottom: spacing.$space-32 !important;
  margin-top: spacing.$space-32 !important;
}
.u-marginSquish-32 {
  margin: spacing.$space-32 spacing.$space-64 !important;
}

.u-margin-64 {
  margin: spacing.$space-64 !important;
}
.u-marginTop-64 {
  margin-top: spacing.$space-64 !important;
}
.u-marginRight-64 {
  margin-right: spacing.$space-64 !important;
}
.u-marginBottom-64 {
  margin-bottom: spacing.$space-64 !important;
}
.u-marginLeft-64 {
  margin-left: spacing.$space-64 !important;
}
.u-marginHorizontal-64 {
  margin-left: spacing.$space-64 !important;
  margin-right: spacing.$space-64 !important;
}
.u-marginVertical-64 {
  margin-bottom: spacing.$space-64 !important;
  margin-top: spacing.$space-64 !important;
}

//PADDING
.u-padding-n {
  padding: 0 !important;
}
.u-paddingTop-n {
  padding-top: 0 !important;
}
.u-paddingRight-n {
  padding-right: 0 !important;
}
.u-paddingBottom-n {
  padding-bottom: 0 !important;
}
.u-paddingLeft-n {
  padding-left: 0 !important;
}
.u-paddingHorizontal-n {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.u-paddingVertical-n {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.u-padding-2 {
  padding: spacing.$space-2 !important;
}
.u-paddingTop-2 {
  padding-top: spacing.$space-2 !important;
}
.u-paddingRight-2 {
  padding-right: spacing.$space-2 !important;
}
.u-paddingBottom-2 {
  padding-bottom: spacing.$space-2 !important;
}
.u-paddingLeft-2 {
  padding-left: spacing.$space-2 !important;
}
.u-paddingHorizontal-2 {
  padding-left: spacing.$space-2 !important;
  padding-right: spacing.$space-2 !important;
}
.u-paddingVertical-2 {
  padding-bottom: spacing.$space-2 !important;
  padding-top: spacing.$space-2 !important;
}
.u-paddingSquish-2 {
  padding: spacing.$space-2 spacing.$space-4 !important;
}

.u-padding-4 {
  padding: spacing.$space-4 !important;
}
.u-paddingTop-4 {
  padding-top: spacing.$space-4 !important;
}
.u-paddingRight-4 {
  padding-right: spacing.$space-4 !important;
}
.u-paddingBottom-4 {
  padding-bottom: spacing.$space-4 !important;
}
.u-paddingLeft-4 {
  padding-left: spacing.$space-4 !important;
}
.u-paddingHorizontal-4 {
  padding-left: spacing.$space-4 !important;
  padding-right: spacing.$space-4 !important;
}
.u-paddingVertical-4 {
  padding-bottom: spacing.$space-4 !important;
  padding-top: spacing.$space-4 !important;
}
.u-paddingSquish-4 {
  padding: spacing.$space-4 spacing.$space-8 !important;
}

.u-padding-8 {
  padding: spacing.$space-8 !important;
}
.u-paddingTop-8 {
  padding-top: spacing.$space-8 !important;
}
.u-paddingRight-8 {
  padding-right: spacing.$space-8 !important;
}
.u-paddingBottom-8 {
  padding-bottom: spacing.$space-8 !important;
}
.u-paddingLeft-8 {
  padding-left: spacing.$space-8 !important;
}
.u-paddingHorizontal-8 {
  padding-left: spacing.$space-8 !important;
  padding-right: spacing.$space-8 !important;
}
.u-paddingVertical-8 {
  padding-bottom: spacing.$space-8 !important;
  padding-top: spacing.$space-8 !important;
}
.u-paddingSquish-8 {
  padding: spacing.$space-8 spacing.$space-12 !important;
}

.u-padding-12 {
  padding: spacing.$space-12 !important;
}
.u-paddingTop-12 {
  padding-top: spacing.$space-12 !important;
}
.u-paddingRight-12 {
  padding-right: spacing.$space-12 !important;
}
.u-paddingBottom-12 {
  padding-bottom: spacing.$space-12 !important;
}
.u-paddingLeft-12 {
  padding-left: spacing.$space-12 !important;
}
.u-paddingHorizontal-12 {
  padding-left: spacing.$space-12 !important;
  padding-right: spacing.$space-12 !important;
}
.u-paddingVertical-12 {
  padding-bottom: spacing.$space-12 !important;
  padding-top: spacing.$space-12 !important;
}
.u-paddingSquish-12 {
  padding: spacing.$space-12 spacing.$space-16 !important;
}

.u-padding-16 {
  padding: spacing.$space-16 !important;
}
.u-paddingTop-16 {
  padding-top: spacing.$space-16 !important;
}
.u-paddingRight-16 {
  padding-right: spacing.$space-16 !important;
}
.u-paddingBottom-16 {
  padding-bottom: spacing.$space-16 !important;
}
.u-paddingLeft-16 {
  padding-left: spacing.$space-16 !important;
}
.u-paddingHorizontal-16 {
  padding-left: spacing.$space-16 !important;
  padding-right: spacing.$space-16 !important;
}
.u-paddingVertical-16 {
  padding-bottom: spacing.$space-16 !important;
  padding-top: spacing.$space-16 !important;
}
.u-paddingSquish-16 {
  padding: spacing.$space-16 spacing.$space-24 !important;
}

.u-padding-24 {
  padding: spacing.$space-24 !important;
}
.u-paddingTop-24 {
  padding-top: spacing.$space-24 !important;
}
.u-paddingRight-24 {
  padding-right: spacing.$space-24 !important;
}
.u-paddingBottom-24 {
  padding-bottom: spacing.$space-24 !important;
}
.u-paddingLeft-24 {
  padding-left: spacing.$space-24 !important;
}
.u-paddingHorizontal-24 {
  padding-left: spacing.$space-24 !important;
  padding-right: spacing.$space-24 !important;
}
.u-paddingVertical-24 {
  padding-bottom: spacing.$space-24 !important;
  padding-top: spacing.$space-24 !important;
}
.u-paddingSquish-24 {
  padding: spacing.$space-24 spacing.$space-32 !important;
}

.u-padding-32 {
  padding: spacing.$space-32 !important;
}
.u-paddingTop-32 {
  padding-top: spacing.$space-32 !important;
}
.u-paddingRight-32 {
  padding-right: spacing.$space-32 !important;
}
.u-paddingBottom-32 {
  padding-bottom: spacing.$space-32 !important;
}
.u-paddingLeft-32 {
  padding-left: spacing.$space-32 !important;
}
.u-paddingHorizontal-32 {
  padding-left: spacing.$space-32 !important;
  padding-right: spacing.$space-32 !important;
}
.u-paddingVertical-32 {
  padding-bottom: spacing.$space-32 !important;
  padding-top: spacing.$space-32 !important;
}
.u-paddingSquish-32 {
  padding: spacing.$space-32 spacing.$space-64 !important;
}

.u-padding-64 {
  padding: spacing.$space-64 !important;
}
.u-paddingTop-64 {
  padding-top: spacing.$space-64 !important;
}
.u-paddingRight-64 {
  padding-right: spacing.$space-64 !important;
}
.u-paddingBottom-64 {
  padding-bottom: spacing.$space-64 !important;
}
.u-paddingLeft-64 {
  padding-left: spacing.$space-64 !important;
}
.u-paddingHorizontal-64 {
  padding-left: spacing.$space-64 !important;
  padding-right: spacing.$space-64 !important;
}
.u-paddingVertical-64 {
  padding-bottom: spacing.$space-64 !important;
  padding-top: spacing.$space-64 !important;
}

// TYPOGRAPHY
.u-fontSize-large {
  font-size: typography.$font-size-large !important;
}
.u-fontSize-medium {
  font-size: typography.$font-size-base !important;
}
.u-fontSize-small {
  font-size: typography.$font-size-small !important;
}

.u-fontWeight-normal {
  font-weight: typography.$font-weight-normal;
}
.u-fontWeight-bold {
  font-weight: typography.$font-weight-bold;
}

// COLORS
// TODO: add more background- and text color utilities
// based on color palette
.u-textColor-red {
  color: colors.$brand-red !important;
}
.u-backgroundColor-transparent {
  background-color: transparent !important;
}
.u-backgroundColor-gray60 {
  background-color: colors.$gray-60 !important;
}
.u-backgroundColor-gray50 {
  background-color: colors.$gray-50 !important;
}
.u-backgroundColor-gray40 {
  background-color: colors.$gray-40 !important;
}
.u-backgroundColor-gray30 {
  background-color: colors.$gray-30 !important;
}
.u-backgroundColor-gray20 {
  background-color: colors.$gray-20 !important;
}
.u-backgroundColor-gray10 {
  background-color: colors.$gray-10 !important;
}
.u-backgroundColor-gray5 {
  background-color: colors.$gray-5 !important;
}
.u-backgroundColor-white {
  background-color: colors.$white !important;
}

.u-textColor-blue {
  color: colors.$brand-blue !important;
}
.u-textColor-darkBlue {
  color: colors.$brand-dark-blue !important;
}
.u-textColor-gray60 {
  color: colors.$gray-60 !important;
}
.u-textColor-gray50 {
  color: colors.$gray-50 !important;
}
.u-textColor-gray40 {
  color: colors.$gray-40 !important;
}
.u-textColor-gray30 {
  color: colors.$gray-30 !important;
}
.u-textColor-gray20 {
  color: colors.$gray-20 !important;
}
.u-textColor-gray10 {
  color: colors.$gray-10 !important;
}
.u-textColor-gray5 {
  color: colors.$gray-5 !important;
}

@use 'variables/colors';

.CustomerError {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
}

.CustomerError__logo {
  height: 120px;
}

.CustomerError__title {
  color: colors.$brand-primary;
  font-weight: normal;
  font-size: 2.5em;
  max-width: 20em;
  text-align: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.CustomerError__message {
  color: colors.$gray-40;
  font-size: 1.25em;
  text-align: center;
  width: 80%;
  padding-top: 1em;
  border-top: 1px solid;
}

@use 'variables/colors';

.PageContainer {
  padding-top: 24px;
}

.PageContainer.PageContainer--with-sidebar-expandable {
  padding-top: 16px;
}

.PageContainer__content {
  /* For pages with limited content we use the below 
    estimation to push the footer to the bottom */
  min-height: calc(100vh - 175px);
}

.PageContainer__sidebar {
  /* Can't quite figure out why this is needed... but without it, the sidebar
   * renders on small devices with padding around it. */
  border: 1px solid transparent;
}

.PageContainer__sidebar-title {
  margin-top: 24px;
  margin-bottom: 24px;
  color: colors.$gray-60;
}

@media (min-width: 768px) {
  .JobPage.PageContainer .PageContainer__sidebar {
    top: 60px;
  }

  /** Large devices, always show sidebar */
  .PageContainer .PageContainer__sidebar {
    position: fixed;
    /* This is position: fixed, so make sure it is rendered below the
     * the fixed top navbar */
    top: 40px;
    bottom: 0;
    left: 0;
    overflow: auto;
    z-index: 1;
  }

  .PageContainer .PageContainer__sidebarToggle {
    display: none;
  }

  .PageContainer .CollapseContent.CollapseContent--inverted {
    /* Adding the `hide-with-fixed-sidebar` class to any element in a
     * page container will hide it when the sidebar is always visible */
    display: none;
  }
}

@media (max-width: 768px) {
  /** Small devices, hide content within `.CollapseContent` sections */
  .PageContainer .CollapseContent {
    display: none;
  }

  .PageContainer__collapsedContent {
    display: flex;
    align-items: center;
    line-height: 40px;
    margin-bottom: 12px;
    height: 40px;
  }

  .PageContainer__collapsedContent:empty {
    display: none;
  }

  /** When reduced but expandable, content in `ExpandableSidebarContent` c
   * can be manually shown by expanding the sidebar. */
  .PageContainer .CollapseContent.CollapseContent--inverted,
  .PageContainer.PageContainer--with-sidebar-expanded
    .CollapseContent--expandable {
    display: block;
  }
}

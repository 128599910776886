@use 'variables/borders';
@use 'variables/colors';
@use 'variables/inputs';
@use 'variables/typography';
@use 'variables/spacing';
@use 'variables/z-index';

.MultiValueInput {
  position: relative;

  &__control {
    align-items: center;
    background-color: colors.$white;
    border-radius: inputs.$input-border-radius;
    color: colors.$gray-60;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    font-size: typography.$font-size-base;
    justify-content: space-between;
    min-height: 40px;
    position: relative;
    transition: all 100ms;

    &--is-disabled {
      opacity: 0.4;
    }
  }

  &--isInvalid &__control {
    border-color: colors.$brand-red;
  }

  &--shouldRenderAsInput &__control {
    border: 1px solid inputs.$input-border-color;
    box-shadow: inset inputs.$input-box-shadow;
  }

  &--shouldRenderAsInput &__value-container {
    padding: spacing.$space-2 spacing.$space-8;
  }

  &__value-container {
    align-items: center;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    overflow: hidden;
    padding-bottom: spacing.$space-2;
    padding-left: 0;
    padding-right: spacing.$space-8;
    padding-top: spacing.$space-2;
    position: relative;
  }

  &__persistentPlaceholder {
    opacity: 0.6;
  }

  &__placeholder {
    margin-left: spacing.$space-2;
    margin-right: spacing.$space-2;
    opacity: 0.6;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__label {
    color: colors.$gray-60;
    display: block;
    font-size: typography.$font-size-base;
    font-weight: typography.$font-weight-bold;
  }

  &__errorMessage {
    color: colors.$brand-red;
    font-size: typography.$font-size-small;
    margin-top: spacing.$space-4;
  }

  &__multi-value {
    background-color: colors.$gray-20;
    border-radius: 2px;
    display: flex;
    margin: spacing.$space-2;
    min-width: 0;
  }

  &__multi-value__label {
    border-radius: 2px;
    color: colors.$gray-50;
    cursor: default;
    overflow: hidden;
    padding: spacing.$space-2;
    padding-left: spacing.$space-8;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__multi-value__remove {
    align-items: center;
    border-radius: 2px;
    color: colors.$gray-50;
    cursor: pointer;
    display: flex;
    padding-left: spacing.$space-4;
    padding-right: spacing.$space-4;

    &:hover,
    &:focus {
      color: colors.$gray-60;
    }
  }

  &--is-disabled &__multi-value__remove {
    cursor: default;

    &:hover,
    &:focus {
      color: colors.$gray-50;
    }
  }

  &__menu {
    background-color: colors.$white;
    border: 1px solid colors.$gray-20;
    border-radius: borders.$border-radius-large;
    box-shadow: 0 4px 7px 0 rgba(colors.$black-shadow, 0.12);
    padding-bottom: spacing.$space-2;
    position: absolute;
    top: calc(100% + spacing.$space-4);
    width: 100%;
    z-index: z-index.$z-index-dropdown;
  }

  &__menu-list {
    margin-left: spacing.$space-8;
    margin-right: spacing.$space-8;
    max-height: 300px;
    overflow-y: auto;
    position: relative;
  }

  &__option,
  &__menu-notice {
    align-items: center;
    background-color: transparent;
    border-radius: borders.$border-radius-base;
    color: inherit;
    display: flex;
    font-size: typography.$font-size-base;
    height: 28px;
    margin: spacing.$space-4 spacing.$space-2;
    padding-left: 6px;
    padding-right: 6px;
    user-select: none;
  }

  &__option {
    cursor: pointer;

    &--is-focused {
      background-color: colors.$gray-10;
    }
  }
}

.CheckboxFiltersFormGroup {
  margin-bottom: 16px;
  white-space: nowrap;
  .form-check {
    margin: 0;
    padding: 0;
    .form-check-input {
      margin: 0;
      margin-right: 8px;
    }
  }
  .form-check-label {
    display: inline-block;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    margin: 0;
  }
}
